ion-title {
  font-weight: 600;
  font-size: larger;
}

ion-button {
  --border-radius: 8px;
}

ion-toast {
  --height: 70px;
  --width: 90%;
}

.ion-1x {
  font-size: 24px !important;
  letter-spacing: 5px;
}

.ion-2x {
  font-size: 32px !important;
  letter-spacing: 10px;
}

.ion-color-roxo {
  --ion-color-base: #7c7eb5;
  --ion-color-base-rgb: 124, 126, 181;
}

.ion-color-rosa {
  --ion-color-base: #cd2653;
  --ion-color-base-rgb: 205, 38, 83;
}

.ion-color-azulmgm {
  --ion-color-base: #0994dc;
  --ion-color-base-rgb: 9, 148, 220;
}

.scan-card-modal-css .modal-wrapper {
  height: 75%;
  width: 90%;
  /*top: 15%;*/
  position: absolute;
  display: block;
  border-radius: 3px;
}

// FIX FOR FIXED LABEL SIZE
.label-fixed {
  min-width: 30% !important;
  max-width: 40% !important;
}

.prefix {
  font-size: large;
  font-weight: 600;
}

.errors {
  font-size: small;
  color: #fff;
  background: var(--ion-color-danger);
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}

/* Alertas */
.compra-text {
  .alert-message {
    text-align: left !important;
    line-height: 1.5em;
  }
}

body.scanner-active {
  background: transparent;
  --background: transparent;
  --ion-background-color: transparent;

  app-root {
    opacity: 0;
  }
}